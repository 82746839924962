import React from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  Field,
  Control,
  Label,
  Input,
  Tag
} from 'sqrl-design-system'

export default () => (
  <div style={{ minHeight: '100vh' }}>
    <h1>SQRL Theme</h1>

    <hr />

    <h1>Heading 1</h1>
    <h2>Heading 2</h2>
    <h3>Heading 3</h3>
    <h4>Heading 4</h4>

    <hr />

    <h1>Tags (Chips)</h1>

    <Tag style={{ marginRight: 8 }}>Hello</Tag>
    <Tag style={{ marginRight: 8 }} isColor="primary">
      Hello
    </Tag>
    <hr />
    <h1>Cards</h1>

    <Card>
      <CardHeader>Card Header</CardHeader>
      <CardContent>Card Body</CardContent>
      <CardFooter>Card Footer</CardFooter>
    </Card>

    <hr />

    <Field>
      <Label>Hello</Label>
      <Control>
        <Input />
      </Control>
    </Field>

    <hr />

    <h1>Buttons</h1>

    <Button style={{ marginRight: 10 }}>Hello</Button>
    <Button className="is-rounded" isColor="primary">
      Hello
    </Button>
  </div>
)
